import { BROWSER_NOTIFICATION_LS_DISABLED } from '../../../../shared/constants';
import { SOUND_NOTIFICATION_LS_DISABLED } from '../../../../shared/hooks/usePlayNotificationSound';
import { GEO_IP_STORAGE_KEYS } from '../../../../shared/typings';

const cleanLocalStorageUseCase = () => {
  const userGeo = localStorage.getItem(GEO_IP_STORAGE_KEYS.GEO_DATA);
  const cpDetails = localStorage.getItem('CHANNEL_PARTNER_DETAILS');
  const isNotificationsDisabled = localStorage.getItem(BROWSER_NOTIFICATION_LS_DISABLED);
  const isSoundNotificationEnabled = localStorage.getItem(SOUND_NOTIFICATION_LS_DISABLED);
  localStorage.clear();
  if (userGeo) {
    localStorage.setItem(GEO_IP_STORAGE_KEYS.GEO_DATA, userGeo);
  }
  if (cpDetails) {
    localStorage.setItem('CHANNEL_PARTNER_DETAILS', cpDetails);
  }
  if (isNotificationsDisabled) {
    localStorage.setItem(BROWSER_NOTIFICATION_LS_DISABLED, isNotificationsDisabled);
  }
  if (isSoundNotificationEnabled) {
    localStorage.setItem(SOUND_NOTIFICATION_LS_DISABLED, isSoundNotificationEnabled);
  }
  console.log('local storage cleaned');
};

export default cleanLocalStorageUseCase;
