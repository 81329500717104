import { SetStateActionWithReset } from '../../../../shared/hooks/usePaginatedQuery';
import { ChatPaginationData } from '../typings';
import { atom, PrimitiveAtom, WritableAtom, Setter } from 'jotai';
import { createPaginatedRequestAtom } from '../../../../shared/utils/request-atom';
import {
  Message,
  MessageTypesEnum,
  NoteMessage,
} from '../typings/message-types';
import { PaginatedQueryRequest } from '../../../../shared/typings/request';
import getMessageDateGroups from '../utils/get-message-date-groups';
import { WabaIntegrationMember } from '../../../../shared/typings/waba';

type WriteGetter = Parameters<WritableAtom<unknown, unknown>['write']>[0];

type PartialMessage = Partial<Message> & { id: string };

const chatIdsCache = new Set<string>();
const messagesCache = new Map<string, PrimitiveAtom<Message>>();
const queueCache = new Map<string, PartialMessage[]>();
const messageRangeCache = new Map<
  string,
  { startIndex: number; endIndex: number }
>();
const chatIdMessageCache = new Map<
  string,
  WritableAtom<
    PaginatedQueryRequest<string, ChatPaginationData>,
    SetStateActionWithReset<PaginatedQueryRequest<string, ChatPaginationData>>,
    void
  >
>([]);
const userToIntegrationCache = new Map<string, Set<string>>();

export default class ChatCache {
  private static instance: ChatCache;

  messagesCache;
  chatCache;
  queueCache;
  chatIds;
  setAtomValue;
  getAtomValue;
  messageRangeCache;
  userToIntegrationCache;

  constructor(get: WriteGetter, set: Setter) {
    this.messagesCache = messagesCache;
    this.chatIds = chatIdsCache;
    this.queueCache = queueCache;
    this.chatCache = chatIdMessageCache;
    this.messageRangeCache = messageRangeCache;
    this.setAtomValue = set;
    this.getAtomValue = get;
    this.userToIntegrationCache = userToIntegrationCache;
  }

  public static getInstance(get?: WriteGetter, set?: Setter): ChatCache {
    if (!ChatCache.instance) {
      if (get && set) {
        ChatCache.instance = new ChatCache(get, set);
        return ChatCache.instance;
      }
    }
    return ChatCache.instance;
  }

  hasCacheId(id: string) {
    return this.chatCache.has(id);
  }

  setQueue(data: { chatId: string; message: PartialMessage }) {
    const { chatId, message } = data;
    const queue = this.queueCache.get(chatId) ?? [];
    this.queueCache.set(chatId, [...queue, message]);
  }

  setBulkQueue(data: { chatId: string; messages: Array<Message> }) {
    const { chatId, messages } = data;
    const queue = this.queueCache.get(chatId) ?? [];
    this.queueCache.set(chatId, [...queue, ...messages]);
  }

  getMessagesAtom(id: string) {
    let chatAtom = chatIdMessageCache.get(id);

    if (!chatAtom) {
      chatAtom = createPaginatedRequestAtom<string, ChatPaginationData>({
        loading: false,
      });
      this.chatIds.add(id);
      chatIdMessageCache.set(id, chatAtom);
    }

    return chatAtom;
  }

  deleteMessageAtom(id: string) {
    return this.messagesCache.delete(id);
  }

  deleteChat(chatId: string) {
    const messageIdsAtom = this.chatCache.get(chatId);

    if (messageIdsAtom) {
      const messageIds = this.getAtomValue(messageIdsAtom).data;
      if (!!messageIds && messageIds.length > 0) {
        messageIds.forEach((id) => this.deleteMessageAtom(id));
      }
    }

    this.chatIds.delete(chatId);
    this.chatCache.delete(chatId);
  }

  deleteChatMessage(chatId: string, messageId: string) {
    const messageIdsAtom = this.chatCache.get(chatId);

    if (messageIdsAtom) {
      const messageIds = this.getAtomValue(messageIdsAtom).data;
      if (
        !!messageIds &&
        messageIds.length > 0 &&
        messageIds.indexOf(messageId) > -1
      ) {
        this.deleteMessageAtom(messageId);

        this.setAtomValue(messageIdsAtom, (prev) => {
          const data = prev.data ?? [];
          return {
            ...prev,
            data: [...data].filter((id) => id !== messageId),
          };
        });
      }
    }
  }

  deleteAllChatMessages(chatId: string) {
    const messageIdsAtom = this.chatCache.get(chatId);
    if (messageIdsAtom) {
      const messageIds = this.getAtomValue(messageIdsAtom).data;

      if(!!messageIds && messageIds.length > 0){
        messageIds.forEach((id) => this.deleteMessageAtom(id));
        this.setAtomValue(messageIdsAtom, {
          ...this.getAtomValue(messageIdsAtom),
          data: [],
        });
      }
    }
  }

  getMessageIndex(chatId: string, messageId: string) {
    if (chatId && messageId) {
      const messageIdsAtom = this.chatCache.get(chatId);
      if (!messageIdsAtom) {
        return -1;
      }
      const messageIds = this.getAtomValue(messageIdsAtom).data;
      return (messageIds ?? []).indexOf(messageId);
    }

    return -1;
  }

  getMessageAtom(id: string) {
    return this.messagesCache.get(id);
  }

  addMessage(chatId: string, message: Message) {
    this.chatIds.add(chatId);
    const messageIdsAtom = this.chatCache.get(chatId);

    if (messageIdsAtom) {
      this.messagesCache.set(message.id, atom(message));
      this.setAtomValue(messageIdsAtom, (prev) => {
        const data = prev.data ?? [];
        return {
          ...prev,
          data: [...data, message.id],
        };
      });
      return;
    }

    this.addChat(chatId, [message]);
  }

  getLastMessage(chatId: string) {
    const messageIdsAtom = this.chatCache.get(chatId);

    if (messageIdsAtom) {
      const messageIds = this.getAtomValue(messageIdsAtom).data;
      if (messageIds) {
        const messageAtom = this.messagesCache.get(
          messageIds[messageIds.length - 1]
        );

        if (messageAtom) {
          return this.getAtomValue(messageAtom);
        }
      }
    }

    return null;
  }

  getMessages(chatId: string) {
    const messageIdsAtom = this.chatCache.get(chatId);

    if (messageIdsAtom) {
      const messageIds = this.getAtomValue(messageIdsAtom).data;
      if (messageIds) {
        return messageIds.map((id) =>
          this.getAtomValue(this.messagesCache.get(id)!)
        );
      }
    }

    return [];
  }

  getMessagesById(messageId: string) {
    if(!this.messagesCache.get(messageId)){
      return null;
    }
    return this.getAtomValue(this.messagesCache.get(messageId)!);
  }

  getDateGroups(chatId: string) {
    const messages = this.getMessages(chatId);
    return getMessageDateGroups(chatId, messages);
  }

  // adds chat to cache if present or not
  addChat(id: string, messages: Message[]) {
    this.chatIds.add(id);

    const sortedNewMessageIds = messages
      .sort((a, b) => a?.messageTime - b?.messageTime)
      .map((message) => {
        this.messagesCache.set(message.id, atom(message));
        return message.id;
      });

    const chatMessageIdsAtom = this.chatCache.get(id);

    if (!chatMessageIdsAtom) {
      const messageIdsAtom = createPaginatedRequestAtom<
        string,
        ChatPaginationData
      >({
        loading: false,
        data: sortedNewMessageIds,
      });

      this.chatCache.set(id, messageIdsAtom);
      return;
    }

    this.setAtomValue(chatMessageIdsAtom, (prev) => {
      const prevData = prev.data ?? [];
      const messageIdsSet = new Set([...prevData, ...sortedNewMessageIds]);
      // TODO: assumption new messages will be shown only after existing messages
      return {
        ...prev,
        data: Array.from(messageIdsSet),
      };
    });
  }

  // only adds messages to cache if chat is present in cache
  setChat(id: string, messages: Message[]) {
    const chatPresentInCache = this.chatIds.has(id);

    if (!chatPresentInCache) return;

    this.addChat(id, messages);
  }

  addChats(chats: Record<string, Message[]>) {
    if (!chats) {
      return;
    }
    Object.keys(chats).forEach((id) => this.addChat(id, chats[id]));
  }

  // a function that checks if messages are in queue and are not in range for that
  // chatId then swaps them from queueCache to messagesCache
  triggerQueueSwap(chatId: string) {
    const messageIdsAtom = this.chatCache.get(chatId);
    const messages = this.queueCache.get(chatId);

    if (!messageIdsAtom || !messages) return;

    const messageIds = this.getAtomValue(messageIdsAtom).data;

    if (!messageIds) return;

    const { startIndex, endIndex } = this.messageRangeCache.get(chatId)!;

    const messagesToSwap = messages?.filter(({ id }) => {
      const messageIdx = messageIds?.indexOf(id);

      return (
        messageIdx >= 0 && (messageIdx < startIndex || messageIdx > endIndex)
      );
    });

    messagesToSwap?.forEach((message) => {
      const messageAtom = this.getMessageAtom(message.id);

      if (messageAtom) {
        this.setAtomValue(messageAtom, (prev) => ({
          ...prev,
          message: {
            ...prev.message,
            ...message.message,
          },
        }));
      }

      this.queueCache.set(
        chatId,
        messages.filter(({ id }) => id !== message.id)
      );
    });
  }

  setMessageRange(data: {
    chatId: string;
    startIndex: number;
    endIndex: number;
  }) {
    const { chatId, startIndex, endIndex } = data;
    this.messageRangeCache.set(chatId, { startIndex, endIndex });
    this.triggerQueueSwap(chatId);
  }

  setUserToIntegrationCache(data: {
    wabaMembers: WabaIntegrationMember[];
    integrationId: string;
  }) {
    const { wabaMembers, integrationId } = data;
    const userIds = wabaMembers.map(({ id }) => id);
    userIds.forEach((userId) => {
      const integrations = this.userToIntegrationCache.get(userId) ?? new Set();
      integrations.add(integrationId);
      this.userToIntegrationCache.set(userId, integrations);
    });
  }

  getUserToIntegrationCache(userId: string) {
    return this.userToIntegrationCache.get(userId);
  }
}

type SendMessageToCacheArgs = (
  | {
      partial: false;
      message: Message;
    }
  | {
      partial: true;
      message: PartialMessage;
    }
) & { chatId: string; queue?: boolean; fromSocket?: boolean };

type DeleteMessageFromCacheArgs = {
  chatId: string;
  messageId: string;
};

export const sendMessageToCache = atom(
  null,
  (get, set, args: SendMessageToCacheArgs) => {
    const { chatId, message, queue = false, fromSocket = false } = args;
    const messageId = message.id;
    const chatCache = ChatCache.getInstance(get, set);
    // don't add messages to chatCache when coming from socket and chat doesn't have any messages
    if (fromSocket && !chatCache.hasCacheId(chatId)) {
      return;
    }

    // if message is to be pushed in the queue for updates when it's not in view
    const messageAtom = chatCache.getMessageAtom(messageId);
    if (queue) {
      // if message is already added to message cache, then it means it was sent locally
      if (messageAtom) {
        if (!fromSocket) {
          const { message: mainMessage, ...rest } = message;
          return set(messageAtom, { ...get(messageAtom), ...rest });
        }
        const savedMsg = { ...get(messageAtom) };
        // when we recieve socket event on msg reaction on user's msg
        if (fromSocket) {
          const { message: mainMessage } = savedMsg;
          switch (message.message?.messageType) {
            case MessageTypesEnum.REACTION: {
              mainMessage.reaction = args.message.message?.reaction;
              mainMessage.agentReaction = args.message.message?.agentReaction;
              break;
            }
            case MessageTypesEnum.NOTE: {
              (mainMessage as NoteMessage).text = (
                args.message.message as NoteMessage
              )?.text;
              break;
            }
            default:
              break;
          }
          return set(messageAtom, { ...savedMsg, ...mainMessage });
        }
        chatCache.setQueue({ chatId, message });
        return;
      }

      // if there is no messageAtom that means message is not in cache, so add it to cache
      if (!chatCache.hasCacheId(chatId) && !args.partial) {
        return chatCache.addChat(chatId, [args.message]);
      }

      if (!args.partial) {
        return chatCache.addMessage(chatId, args.message);
      }
    }

    // add chatId to chatId cache and message to chatIdMessage cache
    if (!chatCache.hasCacheId(chatId) && !args.partial) {
      chatCache.addChat(chatId, [args.message]);
    }

    // add message to message cache
    if (!messageAtom) {
      if (!args.partial) {
        chatCache.addMessage(chatId, args.message);
      }
      return;
    }

    const savedMessageAtId = get(messageAtom);
    if (args.partial) {
      return set(messageAtom, { ...savedMessageAtId, ...args.message });
    }

    return set(messageAtom, { ...args.message });
  }
);

export const deleteMessageFromCache = atom(
  null,
  (get, set, args: DeleteMessageFromCacheArgs) => {
    const { chatId, messageId } = args;

    const chatCache = ChatCache.getInstance(get, set);

    // don't remove message from chat when chat doesn't have any messages
    if (!chatCache.hasCacheId(chatId)) {
      return;
    }

    // if message is to be pushed in the queue for updates when it's not in view
    const messageAtom = chatCache.getMessageAtom(messageId);

    // add message to message cache
    if (!messageAtom) {
      return;
    }

    chatCache.deleteChatMessage(chatId, messageId);
  }
);

export const sendBulkMessagesToCache = atom(
  null,
  (
    get,
    set,
    {
      chatIdMessagesMap,
      setIfPresent,
    }: { chatIdMessagesMap: Record<string, Message[]>; setIfPresent?: boolean }
  ) => {
    const chatCache = ChatCache.getInstance(get, set);
    if (!!chatIdMessagesMap) {
      Object.keys(chatIdMessagesMap).forEach((chatId) => {
        const newMessages = chatIdMessagesMap[chatId] || [];
        if (!setIfPresent || chatCache.hasCacheId(chatId)) {
          chatCache.addChat(chatId, newMessages);
        }
      });
    }
  }
);
