import { ContactData, FrictionlessMessageDataToSend } from './index';
import { CardVariablesFormat, Template, TemplateButtonType, TemplateComponentsArray } from '../../../../shared/typings/template';
import { ChatType } from '../components/chat-list-section/chat-row/typings';
import {
  FrequencyCappingState,
  RetryFrequencyOptions,
  StopRetryingAfterOptions,
} from '../../../../shared/typings/frequency-capping';
import ChatCache from '../states/cache';

export enum MessageTypesEnum {
  TEXT = 'text',
  DOCUMENT = 'document',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  CONTACTS = 'contacts',
  TEMPLATE = 'template',
  SYSTEM = 'system',
  STICKER = 'sticker',
  INTERACTIVE = 'interactive',
  BUTTON = 'button',
  FLOW_RESPONSE = 'flow_response',
  COMMERCE_PRIVACY_IMAGE = 'commerce_privacy_image',
  COMMERCE_PRIVACY_BUTTON = 'commerce_privacy_button',
  LOCATION = 'location',
  REACTION = 'reaction',
  SYSTEM_LOG = 'system_message_v2',
  NOTE = 'note',
}

export enum CommercePrivacyStatusEnum {
  ALLOWED = 'ALLOWED',
  REJECTED = 'REJECTED',
}

export enum FileTypes {
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  PDF = 'application/pdf',
  EXCEL = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  VIDEO_MP4 = 'video/mp4',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  VIDEO_3GP = 'video/3gpp',
  AUDIO_OGG = 'audio/ogg; codecs=opus',
  AUDIO_MPEG = 'audio/mpeg',
  AUDIO_MPGA = 'audio/mpga',
}

export enum TemplateDataKeys {
  HEADER = 'header',
  BODY = 'body',
  FOOTER = 'footer',
  BUTTON = 'button',
  CARDS = 'cards',
}

export type TemplateHeaderType = {
  data?: Array<
    | {
        type: MessageTypesEnum.TEXT;
        text: string;
      }
    | {
        type: MessageTypesEnum.IMAGE;
        link: string;
      }
    | {
        type: MessageTypesEnum.VIDEO;
        link: string;
      }
    | {
        type: MessageTypesEnum.DOCUMENT;
        link: string;
      }
  >;
};

export type TemplateHeaderItemTypeForCard =
  | {
      type: MessageTypesEnum.IMAGE;
      link: string;
    }
  | {
      type: MessageTypesEnum.VIDEO;
      link: string;
    }
  | {
      type: MessageTypesEnum.DOCUMENT;
      link: string;
    };

export type TemplateHeaderTypeForCard = {
  data?: Array<TemplateHeaderItemTypeForCard>;
};
export type TemplateButtonDataType = {
  data: Array<{
    type: TemplateButtonType;
    text: string;
    url?: string;
    phoneNumber?: string;
    example?: string;
  }>;
};
export type TemplateBodyType = {
  data: Array<{
    type: MessageTypesEnum.TEXT;
    text: string;
  }>;
};
export type TemplateCardDataType = {
  body: TemplateBodyType;
  header: TemplateHeaderTypeForCard;
  button?: TemplateButtonDataType;
  mode?: 'message' | 'preview';
  time?: string | number;
  stats?: ChatStats;
  cardIndex?: number;
  previewData?: CardVariablesFormat[];
  components?: TemplateComponentsArray;
  mediaViewerHandler?: () => void;
  onHandleMenuItemClick?: (pageDetail: string, buttonText: string, linkId: string, cardIndex?: number) => void;
};
export interface TemplateData {
  [TemplateDataKeys.HEADER]: TemplateHeaderType;
  [TemplateDataKeys.BUTTON]: TemplateButtonDataType;
  [TemplateDataKeys.CARDS]?: TemplateCardDataType[];
  [TemplateDataKeys.BODY]: TemplateBodyType;

  [TemplateDataKeys.FOOTER]: {
    data?: Array<{
      type: MessageTypesEnum.TEXT;
      text: string;
    }>;
  };
}
export interface TextMessage {
  text: string;
  isSlaActive?: boolean;
  slaDueDate?: string;
  messageType: MessageTypesEnum.TEXT;
}

export interface ReactionMessage {
  reactionMessage: {
    replyMessageId: string;
    emoji: string;
  };
  messageType: MessageTypesEnum.REACTION;
}

export interface ContactsMessage {
  contactsData: ContactData[];
  messageType: MessageTypesEnum.CONTACTS;
}

export interface AudioMessage {
  size: number;
  fileUrl: string;
  duration: number;
  fileName: string;
  messageType: MessageTypesEnum.AUDIO;
  fileType: string;
}

export interface VideoMessage {
  size?: number;
  fileUrl: string;
  duration?: number;
  fileName: string;
  text?: string;
  width?: number;
  height?: number;
  thumbnailUrl: string;
  messageType: MessageTypesEnum.VIDEO;
  fileType: string;
}

export type SystemMessageLink = {
  type: SystemMessageLinkType;
  start: number;
  end: number;
  id: string;
};

export interface SystemMessage {
  messageType: MessageTypesEnum.SYSTEM;
  text: string;
  isSlaActive?: boolean;
  slaDueDate?: string;
  reaction?: {
    text: string;
  };
  botRunId?: string;
  botRunLogId?: string;
  links?: SystemMessageLink[];
}

export enum SystemMessageLinkType {
  BOT = 'BOT',
  TEMPLATE = 'TEMPLATE',
}

export type LogSegments = {
  type: 'TEXT' | SystemMessageLinkType;
  text: string;
  id?: string;
};

export type SystemLogMessage = {
  messageType: MessageTypesEnum.SYSTEM_LOG;
  text: string;
  reaction?: {
    text: string;
  };
  botRunId?: string;
  botRunLogId?: string;
  links?: SystemMessageLink[];
};

export interface ImageMessage {
  size?: number;
  width?: number;
  height?: number;
  fileUrl: string;
  text?: string;
  fileType?: string;
  messageType: MessageTypesEnum.IMAGE;
  thumbnailUrl?: string;
  fileName?: string;
}

export interface StickerMessage {
  size?: number;
  width?: number;
  height?: number;
  fileUrl: string;
  text?: string;
  fileType?: string;
  messageType: MessageTypesEnum.STICKER;
  thumbnailUrl?: string;
  fileName?: string;
}

export interface DocumentMessage {
  text?: string;
  size?: number;
  fileUrl: string;
  pageCount?: number;
  fileName: string;
  fileType: string;
  messageType: MessageTypesEnum.DOCUMENT;
  thumbnailUrl?: string;
}

export interface TemplateMessage {
  messageType: MessageTypesEnum.TEMPLATE;
  templateMessage: TemplateData;
}

export interface FlowResponseMessage {
  messageType: MessageTypesEnum.FLOW_RESPONSE;
  flowResponse: { id: string; value: string }[];
  responseObject: Record<string, string | number | boolean | Record<string, string | number | boolean>>;
}

export interface LocationMessage {
  latitude: string;
  longitude: string;
  messageType: MessageTypesEnum.LOCATION;
}

export interface PrivacyPayload {
  state: string;
  catalogueId: string;
  catalogueName: string;
  alreadyApproved: boolean;
  alreadyRejected: boolean;
  rejectButtonText: string;
  approveButtonText: string;
}

export interface CommercePrivacyImage {
  size: number;
  text: string;
  width: number;
  height: number;
  fileUrl: string;
  fileType: string;
  messageType: MessageTypesEnum.COMMERCE_PRIVACY_IMAGE;
  thumbnailUrl: string;
  privacyPayload: PrivacyPayload;
}

export interface CommercePrivacyButton {
  text: string;
  messageType: MessageTypesEnum.COMMERCE_PRIVACY_BUTTON;
  privacyPayload: PrivacyPayload;
}
export interface TemplateButtonAction {
  text: string;
  messageType: MessageTypesEnum.BUTTON;
  payload: string;
}

export interface CommercePrivacyAccessChat {
  chatId: string;
  chatType: ChatType;
  chatTypeId: string;
  assignedUserId: string;
  assignedUserName: string;
  name: string;
  phoneNumber: string;
  imageUrl: string;
  unreadCount: number;
  senderUser?: any;
}

export interface CommercePrivacyAccessData {
  chatId: string;
  messageId: string;
  message: CommercePrivacyImage;
  chat: CommercePrivacyAccessChat;
  messageTime: number;
  status: string;
  statusTimestamp: Date;
  senderId: string;
  senderType: MessageOrigin;
  orgId: string;
}
interface Reaction {
  reaction?: {
    text: string;
    messageType?: MessageTypesEnum.REACTION;
  };
  agentReaction?: {
    text: string;
    messageType: MessageTypesEnum.REACTION;
    agentId: string;
    agentName: string;
  };
}
type BaseMessageTypes =
  | TextMessage
  | AudioMessage
  | VideoMessage
  | DocumentMessage
  | ImageMessage
  | ContactsMessage
  | TemplateMessage
  | FlowResponseMessage
  | StickerMessage
  | SystemMessage
  | InteractiveMessage
  | CommercePrivacyImage
  | CommercePrivacyButton
  | TemplateButtonAction
  | LocationMessage
  | ReactionMessage
  | SystemLogMessage
  | NoteMessage;

type MessageTypesWithReaction<T> = T & Reaction;

export type MessageTypes = MessageTypesWithReaction<BaseMessageTypes>;

export enum MessageOrigin {
  CUSTOMER = 'CUSTOMER',
  USER = 'USER',
  SYSTEM = 'SYSTEM',
}

export enum MessageMetadataStatus {
  PROCESSING = 'PROCESSING',
  CAN_ADD_TO_ORDER = 'CAN_ADD_TO_ORDER',
  ADDED_TO_CART = 'ADDED_TO_CART',
}

export interface MessageMetadata {
  status: MessageMetadataStatus;
}

export interface OrderData {
  itemTotal: number;
  currencySymbol: string;
  orderTotal: number;
  viewLink: string;
}

export interface BroadcastFrequencyCappingSettings {
  id?: string;
  retryImmediately?: boolean;
  endDate?: string;
  frequency?: RetryFrequencyOptions;
  stopRetryingAfter?: StopRetryingAfterOptions;
  customTime?: string;
  shouldSendRetryOutsideWorkingHours?: boolean;
  timeZone?: string;
  lastExecutedAt?: string;
  nextScheduledAt?: string;
  retryFailedMessages?: boolean; //only for FE use,not coming from BE
  state?: FrequencyCappingState;
}

export type BaseSenderUser = {
  id: string | null;
  name: string | null;
  imageUrl: string | null;
};

export type Message = {
  id: string;
  message: MessageTypes;
  templateId?: string | null;
  senderId: string;
  messageOriginType: MessageOrigin;
  readCount: ChatStats['readCount'];
  replyMessage?: Message;
  repliesCount: ChatStats['repliesCount'];
  deliveryCount: ChatStats['deliveryCount'];
  erroredCount: ChatStats['erroredCount'];
  sentCount: ChatStats['sentCount'];
  senderUser?: BaseSenderUser;
  messageTime: number;
  messageMetadata: MessageMetadata | null;
  totalCount: ChatStats['totalCount'];
  errorMessage: string;
  quickActionButtonStats?: ChatStats['quickActionButtonStats'];
  linkAnalytics?: ChatStats['linkAnalytics'];
  errorCountFrequencyCapping?: ChatStats['errorCountFrequencyCapping'];
  cardLinkAnalytics?: Array<CardLinkAnalytics>;
  adReferralData?: AdReferralData;
  unreadCount: ChatStats['unreadCount'];
  noResponseCount: ChatStats['noResponseCount'];
  broadcastFrequencyCappingSettings?: BroadcastFrequencyCappingSettings;
  canRetry?: boolean;
  retryFunction?: () => Promise<unknown>;
  groupName?: string;
  groupViewChatId?: string;
  showReadMore?: boolean;
};

export type AdReferralData = {
  sourceUrl: string;
  sourceId: string;
  sourceType: string;
  body: string;
  headline: string;
} & (
  | {
      mediaType: 'image';
      imageUrl: string;
    }
  | {
      mediaType: 'video';
      videoUrl: string;
      thumbnailUrl: string;
    }
);

export enum ScheduledMessageStatus {
  ENQUEUED = 'ENQUEUED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  DELETED = 'DELETED',
}

export interface EnqueuedMessage {
  status: ScheduledMessageStatus;
  chatMessageId: string;
  eventTimestamp: number;
  messageType: ChatType;
}
export interface LinkAnalyticsType {
  actualURL: string;
  linkVisitCount: number;
  linkId: string;
}
export interface CardLinkAnalytics {
  cardIndex: number;
  linkAnalytics: Array<LinkAnalyticsType>;
}
export interface ChatStats {
  repliesCount: number;
  deliveryCount: number;
  erroredCount: number;
  errorCountFrequencyCapping?: number;
  readCount: number;
  sentCount: number;
  totalCount: number;
  cardButtonStats?: Array<{
    index: number;
    buttons: Array<{ text: string; count: number }>;
  }>;
  cardLinkAnalytics?: Array<CardLinkAnalytics>;
  quickActionButtonStats?: Array<{ text: string; count: number }>;
  linkAnalytics?: Array<{
    linkId: string;
    actualURL: string;
    linkVisitCount: number;
  }>;
  unreadCount: number;
  noResponseCount: number;
}

export interface SendMessage {
  message: MessageTypes | TemplateSendMessage;
  chatId: string;
  phoneNumber: string;
  chatMessageId: string;
  messageTimestamp: number;
  scheduledTimestamp?: number;
  replyMessageId?: string;
}

export interface SendClosedConversationTemplate {
  message: FrictionlessMessageDataToSend;
  chatId: string;
  phoneNumber: string;
  integrationId: string;
  chatMessageId: string;
  messageTimestamp: number;
  scheduledTimestamp?: number;
  replyMessageId?: string;
}

interface ComponentParameter {
  type: string;
  link?: string; // Optional for image type
  text?: string; // Optional for text type
}

interface Component {
  type: string;
  parameters: ComponentParameter[];
}

interface Card {
  cardIndex: number;
  components: Component[];
}

interface CardVariable {
  cardVariables: Card[];
}
export interface SendBroadcastMessage {
  message: TemplateSendMessage;
  groupId: string;
  integrationId: string;
  scheduledTimestamp?: number;
  messageTimestamp: number;
  broadcastFrequencyCappingSettings?: FrequencyCappingSettings;
}

export interface FrequencyCappingSettings {
  frequency: RetryFrequencyOptions;
  stopRetryingAfter: StopRetryingAfterOptions;
  shouldSendRetryOutsideWorkingHours?: boolean;
  customTime?: string;
  timeZone?: string;
  endDate?: string;
}

export interface TemplateSendMessage {
  messageType: MessageTypesEnum;
  templateMessage: {
    id: number;
    cardVariables?: CardVariablesFormat[];
    variables?: Array<{
      type: string;
      text?: string;
      link?: string;
      fileName?: string;
    }>;
  };
}

export interface SendTemplateMessage {
  message: TemplateSendMessage;
  chatId: string;
  chatMessageId: string;
  customerId?: string;
  groupId?: string;
  messageTimestamp: number;
  broadcastFrequencyCappingSettings?: FrequencyCappingSettings;
}

export interface MessageInfo {
  details: {
    deliveredAt?: number;
    readAt?: number;
    failedAt?: number;
    failedReason?: string;
    template?: Template
  } | null;
}

export interface InteractiveMessage {
  messageType: MessageTypesEnum.INTERACTIVE;
  interactiveMessage: InteractiveListMessage | InteractiveButtonMessage;
}

export enum InteractiveMessageTypes {
  BUTTON = 'button',
  LIST = 'list',
}

export interface InteractiveMessageButton {
  id: string;
  title: string;
}

export interface InteractiveListMessage {
  body: {
    text: string;
  };
  type: InteractiveMessageTypes.LIST;
  button: string;
  header: Omit<TextMessage, 'messageType'> & {
    type: MessageTypesEnum.TEXT;
  };
  sections: Array<{
    title?: string;
    rows: Array<{ id: string; title: string }>;
  }>;
}

export interface InteractiveButtonMessage {
  body: {
    text: string;
  };
  type: InteractiveMessageTypes.BUTTON;
  buttons: InteractiveMessageButton[];
  header?:
    | (Omit<ImageMessage, 'messageType' | 'text'> & {
        type: MessageTypesEnum.IMAGE;
      })
    | (Omit<VideoMessage, 'messageType' | 'text'> & {
        type: MessageTypesEnum.VIDEO;
      })
    | (Omit<DocumentMessage, 'messageType' | 'text'> & {
        type: MessageTypesEnum.DOCUMENT;
      })
    | (Omit<TextMessage, 'messageType'> & {
        type: MessageTypesEnum.TEXT;
      });
}

export interface ButtonMessage {
  messageType: MessageTypesEnum.BUTTON;
  text: string;
  payload: string;
}

export interface ChatMessagesResult {
  data: string[] | null | undefined;
  loading: boolean | undefined;
  fetched: boolean | undefined;
  fetchMore: () => void;
  refresh: () => void;
  chatCache: ChatCache;
}

export interface NoteMessage {
  text: string;
  messageType: MessageTypesEnum.NOTE;
}
