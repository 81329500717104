import { AlertColor } from '@mui/material';

export interface SnackState {
  open: boolean;
  message: string;
  type: AlertColor;
}

export const HEADER_HEIGHT = 55;

export const CONNECTION_LOST_STATUS_BAR_HEIGHT = 40;

export const SUBHEADER_HEIGHT = 55;

export const TAGS_HEADER_HEIGHT = 45;

export const CART_HEADER_HEIGHT = 60;

export const NEW_CHANNEL_ROW_HEIGHT = 69;

export const HEADER_ROW_HEIGHT = 64;

export const MESSAGE_BOX_SHADOW = '0px 1px 2px 1px #80745f2c';

export const MESSAGE_SPACING = 12;

export const TEXT_COLOR = '#5F5F85';

export const TEXT_COLOR_LIGHT = '#8484A8';

export const CHAT_BUBBLE_BORDER_COLOR = '#F4F4F9';

export const CHAT_BUBBLE_SHADOW_COLOR = '#d2d2d2';

export const CHAT_BUBBLE_FOOTER_COLOR = '#8A8A9D';

export const RIGHT_MESSAGE_BG_COLOR = 'rgba(229, 255, 239)';

export const LEFT_MESSAGE_TIME_COLOR = '#5A5A5A';

export const RIGHT_MESSAGE_TIME_COLOR = '#426C53';

export const VARIABLES_BORDER_COLOR = 'rgba(190, 190, 213, 1)';

export const APP_BACKGROUND_COLOR = '#ffffff';

export const LIGHT_GREY = '#FBFBFC';

export const WHITE = '#ffffff';

export const BLACK = '#2d2d2d';

export const GREEN = '#29a471';

export const LIGHT_GREEN = '#f2f9f4';

export const ERROR_COLOR = '#ff1744';

export const WARNING_COLOR = '#ffa726';

export interface Country {
  id: string;
  countryCode: string;
  label: string;
  symbol: string;
}

export interface Geo {
  geo: {
    city: string;
    country: string;
    countryCode: string;
    currency: string;
    region: string;
    regionName: string;
    status: string;
    timezone: string;
    zip: string;
    currencyCode: string;
  };
}

export const BORDER_COLOR = '#e0e0ea';

export const ERROR_BORDER = '#ef5350';

export const PURPLE_COLOR = '#414175';

export const SIDEBAR_WIDTH = 54;

export const HTTPS_PREFIX = 'https://';

export enum FCMNotificationType {
  MESSAGE = 'message',
  CHAT_ASSIGNED = 'chat_assigned',
  CHAT_ASSIGNEE_UPDATED = 'chat_assignee_updated',
  TEMPLATE_STATUS_UPDATE = 'template_status_update',
  CUSTOMER_NOTE_DELETED = 'customer_note_deleted',
  CUSTOMER_NOTE = 'customer_note',
}

interface ButtonBgColorTypes {
  SUCCESS_BG_COLOR: string;
  WARNING_BG_COLOR: string;
}

export const buttonBgColor: ButtonBgColorTypes = {
  SUCCESS_BG_COLOR: `${GREEN}`,
  WARNING_BG_COLOR: `${ERROR_COLOR}`,
};

export enum REQUEST_INTEGRATION_STORAGE_KEYS {
  SHOW_POPUP = 'SHOW_POPUP',
}

export enum TEMPLATE_CREATION_RESTRICTION_ERROR_MODAL_STORAGE_KEYS {
  SHOW_ACCOUNT_DISABLED_MODAL = 'SHOW_ACCOUNT_DISABLED_MODAL',
}

export enum GEO_IP_STORAGE_KEYS {
  GEO_DATA = 'GEO_DATA',
}

export enum SocketStatus {
  CONNECTED = 'connected',
  NOT_CONNECTED = 'not_connected',
}

export interface SocketStatusData {
  [key: string]: {
    status: SocketStatus;
    message: string;
  };
}

export interface DispatchModalState {
  open: boolean;
  email?: string;
  phone?: string;
}

export enum WabaNumberStatus {
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
  RESTRICTED = 'RESTRICTED',
  DISABLED = 'DISABLE',
  REINSTATE = 'REINSTATE',
}

export enum WabaNumberQualityRating {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}
export interface wabaInfo {
  wabaStatus: WabaNumberStatus;
  title: string;
  description: string;
  resource?: {
    name: string;
    url: string;
  };
  externalResource?: {
    name: string;
    url: string;
  };
  additionalInformationV2?: {
    wabaRestrictions: string[];
    accountViolations: {
      violationType: string;
      violationReceivedAt: string;
    }[];
  };
  isWabaVerified: boolean;
  wabaId?: string;
  businessId?: string;
}

export enum WhatsappMessagingLimit {
  TIER_NOT_SET = 'TIER_NOT_SET',
  TIER_50 = 'TIER_0.05K',
  TIER_250 = 'TIER_0.25K',
  'TIER_0.25K' = 'TIER_250',
  TIER_1K = 'TIER_1K',
  TIER_10K = 'TIER_10K',
  TIER_100K = 'TIER_100K',
  TIER_UNLIMITED = 'TIER_UNLIMITED',
}
export interface IntegrationInfo {
  integrationId: string;
  integrationName: string;
  integrationType: string;
  qualityRating: WabaNumberQualityRating;
  messagingLimit?: WhatsappMessagingLimit;
  conversationInitiatedInLast24Hours: number;
  wabaInfo: wabaInfo;
  wabaNumber: string;
  wabaProfileUrl: string;
}

export interface WabaInfoResponse {
  integrations: IntegrationInfo[];
}
