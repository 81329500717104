import { FiltersState } from '../../../../states/chat-list';
import {
  AssignedSocketUpdates,
  ChatStatuses,
  ChatsUpdates,
  ConversationTag,
  EligibleMessageTypes,
  GroupLevelAccess,
} from '../../../../typings';
import { ChatUnreadCountBroadcast } from '../../../../typings/chat';
import {
  Message,
  MessageMetadata,
  MessageTypesEnum,
  NoteMessage,
  TemplateData,
} from '../../../../typings/message-types';

export interface ChatRowData {
  id: string;
  unreadCount: number;
  assignedUserId: string | null;
  assignedUserName?: string | null;
  chatType: ChatType;
  chatTypeId: string;
  name: string;
  phoneNumber: string;
  imageUrl: string;
  canManageGroupAccess?: boolean;
  groupAccessLevel?: GroupLevelAccess;
  lastMessage?: LastMessage;
  tags: ConversationTag[];
  selected?: boolean;
  senderUser?: {
    id: string;
    name: string;
    imageUrl: string;
  };
  isDone?: boolean;
  scrollToMessageId?: string;
  integrationId: string;
  integrationWabaNumber: string;
  integrationWabaPhoneName: string;
  integrationDisplayName: string;
  isSlaActive: boolean;
  slaDueDate?: string;
  eligibleMessageTypes?: Array<EligibleMessageTypes>;
  assigneeUpdatedTime?: number;
}

export type SearchChat = Omit<ChatRowData, 'senderUser'>;

export type SearchChatMessage = {
  id: string;
  unreadCount: number;
  assignedUserId: string | null;
  assignedUserName: string | null;
  chatType: ChatType;
  chatTypeId: string;
  name: string | null;
  phoneNumber: string;
  imageUrl: string;
  chatMessageId: string;
  message: string;
  messageTime: number;
  integrationWabaNumber: string;
  integrationId: string;
  integrationWabaPhoneName: string;
  integrationDisplayName: string;
  isSlaActive: boolean;
  slaDueDate?: string;
};
export interface SearchChatPagination {
  hasMoreChats?: boolean;
  nextChatId?: string;
  nextChatTimestamp?: number;
  messageExists?: boolean;
  nextMessageId?: string;
  nextMessageTimestamp?: number;
}

export interface GetChatSearch {
  chats: Array<SearchChat>;
  chatMessages: Array<SearchChatMessage>;
  paginationOptions: SearchChatPagination;
}

export interface GetChatStatusFilterCount {
  type: keyof Pick<typeof FiltersState, 'ALL_CHATS' | 'MY_CHATS' | 'UNASSIGNED' | 'SLA_STATUS'>;
  label: string;
  counts: {
    type: ChatStatuses;
    label: string;
    count?: string;
  }[];
}

export interface GetChatStatusFilterCountOld {
  type: keyof Pick<typeof FiltersState, 'ALL_CHATS' | 'MY_CHATS' | 'UNASSIGNED' | 'SLA_STATUS'>;
  label: string;
  counts: {
    type: ChatStatuses;
    label: string;
    count?: number;
  }[];
}

export interface GetChatStatusFilterCounts {
  filterCounts: GetChatStatusFilterCount[];
}

export interface GetChatStatusFilterCountsOld {
  filterCounts: GetChatStatusFilterCount[];
}

export type SelectedChat = Omit<ChatRowData, 'lastMessage'>;

export interface LastMessage {
  id: string;
  message: MessageData;
  templateId?: string | null;
  senderId: string;
  messageOriginType?: MessageOrigin | string;
  readCount?: number;
  repliesCount?: number;
  deliveryCount?: number;
  erroredCount?: number;
  messageTime: number;
  unreadCount?: number;
  noResponseCount?: number;
}

export enum MessageOrigin {
  CUSTOMER = 'CUSTOMER',
  USER = 'USER',
  SYSTEM = 'SYSTEM',
}

export type MessageData =
  | TextMessageData
  | TemplateMessageData
  | InteractiveMessageData
  | ButtonMessageData
  | NoteMessage
  | {
      messageType:
        | MessageTypesEnum.IMAGE
        | MessageTypesEnum.VIDEO
        | MessageTypesEnum.AUDIO
        | MessageTypesEnum.STICKER
        | MessageTypesEnum.DOCUMENT
        | MessageTypesEnum.CONTACTS
        | MessageTypesEnum.LOCATION
        | MessageTypesEnum.NOTE;
    };

export interface TextMessageData {
  text: string;
  messageType: MessageTypesEnum.TEXT | MessageTypesEnum.SYSTEM | MessageTypesEnum.SYSTEM_LOG;
}

export interface TemplateMessageData {
  messageType: MessageTypesEnum.TEMPLATE;
  templateMessage: TemplateData;
}

export interface InteractiveMessageData {
  messageType: MessageTypesEnum.INTERACTIVE;
  interactiveMessage: {
    body: {
      text: string;
    };
    type: 'button';
    footer?: {
      text: string;
    };
    header?: {
      text: string;
      type: 'text';
    };
    buttons: Array<{
      id: string;
      title: string;
    }>;
  };
}

export interface ButtonMessageData {
  text: string;
  payload: string;
  messageType: MessageTypesEnum.BUTTON;
}

export enum ChatType {
  GROUP = 'GROUP',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum UpdateChatRowReducerActionTypes {
  UPDATE = 'update',
  MARK_DONE = 'MARK_DONE',
  MARK_READ = 'MARK_READ',
}

export interface NewMessageChatRow {
  chat: ChatRowData;
  chatId: string;
  message: MessageData;
  messageId: string;
  messageTime: number;
  orgId: string;
  senderId: string;
  senderType: MessageOrigin;
  replyMessage: Message | null;
  status: string;
  statusTimestamp: number;
  messageMetadata: MessageMetadata;
  messageOriginType: MessageOrigin;
  integrationId: string;
  integrationWabaNumber: string;
  integrationWabaPhoneName: string;
  integrationDisplayName: string;
  validUserIds?: string[];
  validUsersCustomerName?: {
    name: string;
    userId: string;
  }[];
}

export type UpdateChatRowsReducerArgs =
  | {
      data: NewMessageChatRow;
      type: UpdateChatRowReducerActionTypes.UPDATE;
    }
  | {
      data: { id: string; isDone?: boolean };
      type: UpdateChatRowReducerActionTypes.MARK_DONE;
    }
  | {
      data: { id: string };
      type: UpdateChatRowReducerActionTypes.MARK_READ;
    };

export enum ChatFiltersEventReasonTypes {
  ASSIGNEE_UPDATES = 'assignee-updates',
  ASSIGNEE_UPDATES_BULK = 'assignee-updates-bulk',
  CHAT_UPDATES = 'chats-updates',
  MARK_READ = 'mark-read',
  IS_DONE = 'is-done',
  CHAT_SLA_STATUS = 'chat-sla-status',
  ASSIGN_TAGS = 'assign-tags',
  NEW_MESSAGE = 'new-message',
}

export interface NewMessagePayload {
  eventReason: ChatFiltersEventReasonTypes.NEW_MESSAGE;
  eventPayload: NewMessageChatRow;
}

export interface MarkReadPayload {
  eventReason: ChatFiltersEventReasonTypes.MARK_READ;
  eventPayload: ChatUnreadCountBroadcast;
}

export interface IsDonePayload {
  eventReason: ChatFiltersEventReasonTypes.IS_DONE;
  eventPayload: {
    chatId: string;
    isDone: boolean;
    validUserIds?: string[];
  };
}

export interface ChatSlaStatusPayload {
  eventReason: ChatFiltersEventReasonTypes.CHAT_SLA_STATUS;
  eventPayload: {
    chatId: string;
    isSlaActive?: boolean;
    slaDueDate?: string;
    validUserIds?: string[];
  };
}

export interface AssignTagsPayload {
  eventReason: ChatFiltersEventReasonTypes.ASSIGN_TAGS;
  eventPayload: {
    chatId: string;
    tags: ConversationTag[];
    validUserIds?: string[];
  };
}

export interface AssigneeUpdatesPayload {
  eventReason: ChatFiltersEventReasonTypes.ASSIGNEE_UPDATES;
  eventPayload: AssignedSocketUpdates;
}

export interface AssigneeUpdatesBulkPayload {
  eventReason: ChatFiltersEventReasonTypes.ASSIGNEE_UPDATES_BULK;
  eventPayload: AssignedSocketUpdates[];
}

export interface ChatsUpdatesPayload {
  eventReason: ChatFiltersEventReasonTypes.CHAT_UPDATES;
  eventPayload: ChatsUpdates;
}

export type ChatFiltersEventPayload =
  | NewMessagePayload
  | IsDonePayload
  | MarkReadPayload
  | ChatSlaStatusPayload
  | AssignTagsPayload
  | AssigneeUpdatesPayload
  | ChatsUpdatesPayload
  | AssigneeUpdatesBulkPayload;

export interface ChatSearchArgs {
  searchTerm?: string;
}
