import { WabaIntegrationMemberStatus } from './waba';

export enum Permissions {
  READ_ALL_CONVERSATIONS = 'READ_ALL_CONVERSATIONS',
  WRITE_ALL_CONVERSATIONS = 'WRITE_ALL_CONVERSATIONS',
  READ_ASSIGNED_CONVERSATIONS = 'READ_ASSIGNED_CONVERSATIONS',
  WRITE_ASSIGNED_CONVERSATIONS = 'WRITE_ASSIGNED_CONVERSATIONS',
  READ_UNASSIGNED_CONVERSATIONS = 'READ_UNASSIGNED_CONVERSATIONS',
  WRITE_UNASSIGNED_CONVERSATIONS = 'WRITE_UNASSIGNED_CONVERSATIONS',
  ADD_TEAM_MEMBERS = 'ADD_TEAM_MEMBERS',
  DELETE_TEAM_MEMBERS = 'DELETE_TEAM_MEMBERS',
  READ_TEAM_MEMBERS = 'READ_TEAM_MEMBERS',
  UPDATE_TEAM_MEMBERS = 'UPDATE_TEAM_MEMBERS',
  GET_INTEGRATIONS = 'GET_INTEGRATIONS',
  ADD_INTEGRATION = 'ADD_INTEGRATION',
  CONNECT_INTEGRATION = 'CONNECT_INTEGRATION',
  DISCONNECT_INTEGRATION = 'DISCONNECT_INTEGRATION',
  GET_CUSTOMERS = 'GET_CUSTOMERS',
  GET_ANALYTICS_WIDGETS = 'GET_ANALYTICS_WIDGETS',
  WRITE_ANALYTICS_WIDGETS = 'WRITE_ANALYTICS_WIDGETS',
  DELETE_ANALYTICS_WIDGETS = 'DELETE_ANALYTICS_WIDGETS',
  MANAGE_WALLET = 'MANAGE_WALLET',
  MANAGE_CHANNELS = 'MANAGE_CHANNELS',
  MANAGE_CHANNEL_ACCESS = 'MANAGE_CHANNEL_ACCESS',
  MANAGE_CREATED_CHANNEL_ACCESS = 'MANAGE_CREATED_CHANNEL_ACCESS',
  CHANNEL_ACCESS_LEVEL_FULL_ACCESS = 'CHANNEL_ACCESS_LEVEL_FULL_ACCESS',
  CHANNEL_ACCESS_LEVEL_SEND_ONLY_ACCESS = 'CHANNEL_ACCESS_LEVEL_SEND_ONLY_ACCESS',
  CHANNEL_ACCESS_LEVEL_NO_ACCESS = 'CHANNEL_ACCESS_LEVEL_NO_ACCESS',
  PRIVACY_BOT = 'PRIVACY_BOT',
  READ_TEMPLATES = 'READ_TEMPLATES',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  READ_BOTS = 'READ_BOTS',
  READ_TEAMS = 'READ_TEAMS',
  READ_CUSTOMER_PHONE = 'READ_CUSTOMER_PHONE',
  EXPORT_GROUP_MEMBERS = 'EXPORT_GROUP_MEMBERS',
  MANAGE_ROLES = 'MANAGE_ROLES',
  GET_BILLING_DETAILS = 'GET_BILLING_DETAILS',
  UPDATE_BILLING_DETAILS = 'UPDATE_BILLING_DETAILS',
  GET_WALLET_INVOICES = 'GET_WALLET_INVOICES',
  DOWNLOAD_WALLET_INVOICES = 'DOWNLOAD_WALLET_INVOICES',
  GET_SUBSCRIPTION_INVOICES = 'GET_SUBSCRIPTION_INVOICES',
  DOWNLOAD_SUBSCRIPTION_INVOICES = 'DOWNLOAD_SUBSCRIPTION_INVOICES',
  GET_WABA_USAGE_SUMMARY = 'GET_WABA_USAGE_SUMMARY',
  DOWNLOAD_WABA_USAGE_SUMMARY = 'DOWNLOAD_WABA_USAGE_SUMMARY',
  GET_REPORTS = 'GET_REPORTS',
  READ_TEAM_ASSIGNED_CONVERSATIONS = 'READ_TEAM_ASSIGNED_CONVERSATIONS',
  CHANGE_CHAT_ASSIGNEE = 'CHANGE_CHAT_ASSIGNEE',
  MANAGE_CUSTOM_FIELDS = 'MANAGE_CUSTOM_FIELDS',
  CAN_READ_CHAT_CUSTOM_FIELDS = 'CAN_READ_CHAT_CUSTOM_FIELDS',
  CAN_EDIT_CHAT_CUSTOM_FIELDS = 'CAN_EDIT_CHAT_CUSTOM_FIELDS',
  CREATE_TEMPLATES = 'CREATE_TEMPLATES',
  ZOHO_INTEGRATION_MANAGER = 'ZOHO_INTEGRATION_MANAGER',
  ACCESS_ADS_INSIGHT = 'ACCESS_ADS_INSIGHT',
  DELETE_CONVERSATIONS = 'DELETE_CONVERSATIONS',
  CREATE_CUSTOMER_NOTE = 'CREATE_CUSTOMER_NOTE',
  UPDATE_CUSTOMER_NOTE = 'UPDATE_CUSTOMER_NOTE',
  DELETE_CUSTOMER_NOTE = 'DELETE_CUSTOMER_NOTE',
}

export interface Permission {
  hasAccess: boolean;
  resources: Array<string>;
}

export interface AllUserPermissions {
  canReadConversation: Permission;
  canWriteConversation: Permission;
  canReadMembers: Permission;
  canAddMembers: Permission;
  canRemoveMembers: Permission;
  canUpdateMembers: Permission;
  canAddIntegration: Permission;
  canConnectIntegration: Permission;
  canDisconnectIntegration: Permission;
  canReadIntegration: Permission;
  canReadTemplates: Permission;
  canDeleteTemplates: Permission;
  canReadBots: Permission;
  canReadTeams: Permission;
  canReadAssignedConversation: Permission;
  canReadCustomerPhone: Permission;
  canWriteAssignedConversation: Permission;
  canReadUnassignedConversation: Permission;
  canWriteUnassignedConversation: Permission;
  canGetCustomers: Permission;
  canGetAnalyticsWidgets: Permission;
  canWriteAnalyticsWidgets: Permission;
  canDeleteAnalyticsWidgets: Permission;
  canManageWallet: Permission;
  canManageChannels: Permission;
  canManageChannelAccess: Permission;
  canManageCreatedChannelAccess: Permission;
  channelAccessLevelFullAccess: Permission;
  channelAccessLevelSendOnlyAccess: Permission;
  channelAccessLevelNoAccess: Permission;
  canManagePrivacyBotAccess: Permission;
  canExportGroupMembers: Permission;
  canManageRoles: Permission;
  canGetBillingDetails: Permission;
  canUpdateBillingDetails: Permission;
  canGetWalletInvoices: Permission;
  canDownloadWalletInvoices: Permission;
  canGetSubscriptionInvoices: Permission;
  canDownloadSubscriptionInvoices: Permission;
  canGetWabaUsageInvoices: Permission;
  canDownloadWabaUsageInvoices: Permission;
  canGetReports: Permission;
  canReadTeamAssignedConversation: Permission;
  canChangeChatAssignee: Permission;
  canManageCustomFields: Permission;
  canReadChatCustomFields: Permission;
  canEditChatCustomFields: Permission;
  canCreateTemplates: Permission;
  canAccessZohoSync: Permission;
  canAccessAdsInsights: Permission;
  canDeleteConversation: Permission;
  canCreateNote: Permission;
  canEditNote: Permission;
  canDeleteNote: Permission;
}

export enum Role {
  Owner = 'OWNER',
  TeamLead = 'TEAM_LEAD',
  TeamMember = 'TEAM_MEMBER',
  TeamMemberAssigned = 'TEAM_MEMBER__ASSIGNED',
  DemoUser = 'DEMO_USER',
}

export interface Access {
  permissions: Set<string>;
  role: Role;
  roles: {
    id: string;
    isGlobalRole: boolean;
    name: Role;
    policies: Array<{
      id: string;
      permissionId: Permissions;
      resources: Array<{ id: string }>;
    }>;
  }[];
}

export enum STATUS {
  CONNECTED = 'CONNECTED',
}

export interface Integration {
  id: string | null;
  name: string;
  status: STATUS;
  type: string;
}

export interface UserStatusData {
  teamId: string;
  status: WabaIntegrationMemberStatus | null;
  statusExpiration: string | null;
  orgId: string;
  userId: string;
}
